import React from "react";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import axiosInstance from "helpers/axiosInstance";
import { GooglApiLocation } from "../reservationFormType";
import { appInsights } from "helpers/appInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch } from "react-redux";

const useStyles = makeStyles({
  option: {
    // Hover
    '&[data-focus="true"]': {
      outline: "3px solid #0066FF !important",
      outlineOffset: "3px",
    },
  },
});

export default function AddressLookup(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [autoCompletePlacesFilter, setAutoCompletePlacesFilter] =
    React.useState("");
  const [places, setPlaces] = React.useState<GooglApiLocation[]>([
    {
      location: "",
      peopleSoftId: "",
      stationId: "",
      latitude: "",
      longitude: "",
      brand: "",
      countryCode: "",
      address1: "",
      address2: "",
      address3: "",
      townOrCity: "",
      postcode: "",
      addressSearch: props.addressSearch ?? "",
    },
  ]);
  const [placesDefault, setPlacesDefault] = React.useState<GooglApiLocation>();
  const [isDefault, setisDefault] = React.useState(false);
  const [isDirty, setisDirty] = React.useState(false);
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (props.locationDestination !== undefined)
      setAutoCompletePlacesFilter(props.locationDestination);
    if (
      props.addressSearch !== undefined &&
      props.addressSearch !== null &&
      props.isexistingRes
    )
      setAutoCompletePlacesFilter(props.addressSearch);
    setisDefault(true);
  }, []);
  React.useEffect(() => {
    const fetchData = async () => {
      await loadData();
    };
    fetchData();
  }, [autoCompletePlacesFilter]);
  React.useEffect(() => {
    if (isDefault && places[0] && places[0]?.stationId !== "") {
      setPlacesDefault(places[0]);
      if (
        (!props.isexistingRes ||
          props.addressSearch === null ||
          props.addressSearch === undefined) &&
        props.isAutoLoad
      ) {
        loadAddressDetailData(places[0]?.stationId, places[0]?.location);
      }
    }
  }, [isDefault, places]);

  const loadData = async() => {
    console.log("Search string="+autoCompletePlacesFilter);
    if (autoCompletePlacesFilter && autoCompletePlacesFilter.trim().length > 2) {
      try{
        const response = await axiosInstance.get(
          `places/autocomplete?filter=${encodeURIComponent(autoCompletePlacesFilter)}`
        );
        if (response.data.success) {
          console.log("Options received: ", response.data.data.data);
          setPlaces(response.data.data.data || []);
        } 
        else
        {
          setPlaces([]);
          console.warn("Error in response:", response.data.messages);
        }
      } catch (error) {
        console.error(`Error retrieving autocomplete data for "${autoCompletePlacesFilter}":`, error);
        setPlaces([]);
        const errorObject = error instanceof Error ? error : new Error("Unexpected error type");
        appInsights.trackException({
          exception: errorObject,
          properties: {
            method: "autocomplete",
            component: "AddressLookup",
          },
          severityLevel: SeverityLevel.Error,
        });
      }
    };
  };

  const loadAddressDetailData = (addressId, locationName) => {
    let locationSearch: GooglApiLocation;
    axiosInstance
      .get(`places/${addressId}`)
      .then((response) => {
        locationSearch = {
          location: locationName,
          peopleSoftId: "",
          stationId: "",
          latitude: response.data.data.latitude,
          longitude: response.data.data.longitude,
          brand: "",
          countryCode: response.data.data.countryCode,
          address1: response.data.data.address1,
          address2: response.data.data.address2,
          address3: response.data.data.address3,
          townOrCity: response.data.data.townOrCity,
          postcode: response.data.data.postcode ? response.data.data.postcode : "",
          addressSearch: locationName,
        };
        props.setlocationSearch(locationSearch);
        props.updateLocationSearch(locationSearch);
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            method: "places",
            Component: "AddressLookup",
          },
          severityLevel: SeverityLevel.Error,
        });
        console.log(error);
      });
  };

  return placesDefault ? (
    <Autocomplete
      id={props.id}
      options={places}
      classes={{
        option: classes.option,
      }}
      filterOptions={(x) => x}
      getOptionLabel={(option: GooglApiLocation) =>
        option ? option.location : ""
      }
      isOptionEqualToValue={(option: GooglApiLocation, value: GooglApiLocation) =>
        option.location === value.location
      }
      defaultValue={placesDefault}
      includeInputInList
      disableClearable
      noOptionsText={t("NoOptions")}
      onInputChange={(event, newInputValue) => {
        if (newInputValue.length > 2) {
          setAutoCompletePlacesFilter(newInputValue);
        }
        setisDefault(false);
      }}
      onChange={(event, newValue: GooglApiLocation) => {
        appInsights.trackEvent({
          name: "Address Changed",
          properties: { Component: "Address Lookup" },
        });
        setisDefault(false);
        const stationId = newValue ? newValue.stationId : "";
        if (stationId !== "")
          loadAddressDetailData(stationId, newValue.location);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          id="field1"
          InputProps={{
            ...params.InputProps,
            endAdornment: null,
          }}
          variant="outlined"
          fullWidth
          autoComplete="nothing"
        />
      )}
    />
  ) : (
    <></>
  );
}
