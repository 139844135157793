import React from "react";
import Grid from "@mui/material/Grid";
import  Divider from "@mui/material/Divider";
import { Box, Link, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import "../views/general.css";
import { useDispatch } from "react-redux";
import configParams from "../configParams";
import {
  clearAlertMessage,
} from "../components/store/actions/shared/alertActions";
import { useTranslation } from "react-i18next";
import QRCode from "Assets/Images/GUSQRCode.jpg";
import { authService } from "services/authService";
import { browsersLanugage } from "helpers/localization";


const LegalContactUs = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(clearAlertMessage());
    document.title = "Enterprise Rent-A-Car - CONTACT US"
  }
);
const isUserAuthenticated = authService.isAuthenticated();

var languageCode = isUserAuthenticated ? authService.getUserLocale() : browsersLanugage();

const baseURL = window.location.hostname.endsWith("etd.enterprise.co.uk")
? configParams.API_BASE_URL
: configParams.API_BASE_URL_EHI;
const userGuideURL = baseURL + "/userGuide";
const qrLink = "https://d36ver3o3fxb7y.cloudfront.net/gus.html?chat-source=field"
const contriesLink = {
  uk: "https://www.enterprise.co.uk/en/business-car-hire/business-hire-sign-up.html",
  de: "https://www.enterprise.de/de/firmenkunden-autovermietung/melden-sie-sich-bei-unserem-business-rental-programm-an.html",
  fr: "https://www.enterprise.fr/fr/business-location-voiture/profitez-de-nos-solutions-professionnelles.html",
  es: "https://www.enterprise.es/es/empresa-alquiler-de-coche/registro-de-alquiler-para-empresas.html",
  us: " https://www.enterprise.com/en/business-car-rental/business-rental-forms/lead-form.html",
  ca: "https://www.enterprise.ca/en/business-car-rental/business-rental-forms/lead-form.html"
}

const ContactDetailsTable = (t) => {
    return (
      <div>
        <TableContainer id="conttactTable" aria-label="Table displaying UK and IE phone contact">
          <Table role="table" style={{ width: "auto", tableLayout: "auto" }}>
          <TableBody style={{width: "100%"}}>
              <TableRow  role="row">
                <TableCell width="6%" role="cell"
                         style={{
                          padding: "0px", borderBottom: "none",
                          fontWeight: "bold", border: "0px solid green", paddingLeft:25
                          }}
                         align="left">
                    <span style={{ marginRight: "8px" }}>•</span>
                    {t("ContactUs.UK")}
                  </TableCell>
                <TableCell role="cell"
                  width="20%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left">
                    +44 800 783 9445
                </TableCell>
              </TableRow>
              <TableRow  role="row">
                <TableCell width="6%" role="cell"
                         style={{
                          padding: "0px", borderBottom: "none",
                          fontWeight: "bold", border: "0px solid green", paddingLeft:25
                          }}
                         align="left">
                    <span style={{ marginRight: "8px" }}>•</span>
                    {t("ContactUs.IE")}
                  </TableCell>
                <TableCell role="cell"
                  width="20%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left">
                    +353 1800554469
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };
  
const NorthAmericaContactDetailsTable = (t) => {
  return (
    <div>
        <TableContainer id="conttactNorthAmericaTable" aria-label="table North America phone contacts">
          <Table role="table" style={{ width: "auto", tableLayout: "auto" }}>
          <TableBody>
            <TableRow  role="row">
            <TableCell width="6%" role="cell"
                         style={{
                          padding: "0px", borderBottom: "none",
                          fontWeight: "bold", border: "0px solid green", paddingLeft:25
                          }}
                         align="left">
                    <span style={{ marginRight: "8px" }}>•</span>
                    {t("ContactUs.US")}
                  </TableCell>
                <TableCell role="cell"
                  width="20%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left">
                    +1-855-573-0012
                </TableCell>
              </TableRow>
              <TableRow  role="row">
                <TableCell width="6%" role="cell"
                         style={{
                          padding: "0px", borderBottom: "none",
                          fontWeight: "bold", border: "0px solid green", paddingLeft:25
                          }}
                         align="left">
                    <span style={{ marginRight: "8px" }}>•</span>
                    {t("ContactUs.CA")}
                  </TableCell>
                <TableCell role="cell"
                  width="20%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left">
                    +1-855-573-0012
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
  );
};

const GUSQRDetailsTable = (t) => {
  return (
    <div>
      <TableContainer id="conttactGUSTable" aria-label="table field support">
        <Table  role="table" style={{ width: "auto", tableLayout: "auto" }}>
          <TableBody>
          <TableRow  role="row">
          <TableCell width="10%" role="cell"
                style={{
                  padding: 0,
                  borderBottom: "none",
                  fontWeight: "bold",
                  border: "0px solid green",
                }}
                align="left">
              <img
                style={{
                  display: "block",
                  maxWidth: "80%",
                  maxHeight: "20%",
                  marginBottom: "10px",
                  paddingLeft: "5px"
                }}
                alt="QR Code"
                id={"QRCodeImage"}
                src={QRCode}
              />
                </TableCell>
              <TableCell role="cell"
                width="20%"
                style={{
                  padding: 0,
                  borderBottom: "none",
                  border: "0px solid green",
                }}
                align="left">
                <Link
                  href={qrLink}
                  className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-caption MuiTypography-colorInherit"
                  id="contactUsLink"
                  color="inherit"
                  variant="caption"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecorationLine: "underline", color: "blue" }}>
                  <Typography variant="caption">
                  {t("ContactUs.ContactUsQRText")}
                  </Typography>
                  <Box component="span" className="visuallyHidden">
                    <Typography variant="caption">
                      {!languageCode
                        ? ",opens new window"
                        : t("Footer.OpenNewWindow")}
                    </Typography>
                  </Box>
                </Link>
                  <div>({t("ContactUs.ContactUsOnlyEuropeText")}) </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const BusinessEnquiryTable = (t) => {
  return(
    <div>
      <div className="paddingtophalfcm paddingbottomhalfcm body2">
      <Typography variant="body2"  gutterBottom>
         {t("ContactUs.UK")}{" /"} {t("ContactUs.IE")}{":"}
      </Typography>

     <Link
          href={contriesLink.uk}
          className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-caption MuiTypography-colorInherit"
          id="contactUsLink"
          color="inherit"
          variant="caption"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecorationLine: "underline", color: "blue" }}>
          <Typography variant="caption">
          Join our Business Rental Programme
          </Typography>
          <Box component="span" className="visuallyHidden">
            <Typography variant="caption">
              {!languageCode
                ? ",opens new window"
                : t("Footer.OpenNewWindow")}
            </Typography>
          </Box>
      </Link> 
      <Typography variant="body2"  gutterBottom className="paddingtophalfcm">
         {t("ContactUs.DE")}{":"}
      </Typography>
      <Link
          href={contriesLink.de}
          className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-caption MuiTypography-colorInherit"
          id="contactUsLink"
          color="inherit"
          variant="caption"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecorationLine: "underline", color: "blue" }}>
          <Typography variant="caption">
          Melden Sie sich bei unserem Business Rental-Programm an
          </Typography>
          <Box component="span" className="visuallyHidden">
            <Typography variant="caption">
              {!languageCode
                ? ",opens new window"
                : t("Footer.OpenNewWindow")}
            </Typography>
          </Box>
      </Link> 
      <Typography variant="body2"  gutterBottom className="paddingtophalfcm">
         {t("ContactUs.FR")}{":"}
      </Typography>
       <Link
          href={contriesLink.fr}
          className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-caption MuiTypography-colorInherit"
          id="contactUsLink"
          color="inherit"
          variant="caption"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecorationLine: "underline", color: "blue" }}>
          <Typography variant="caption">
          Profitez de nos solutions professionnelles
          </Typography>
          <Box component="span" className="visuallyHidden">
            <Typography variant="caption">
              {!languageCode
                ? ",opens new window"
                : t("Footer.OpenNewWindow")}
            </Typography>
          </Box>
      </Link> 
       <Typography variant="body2"  gutterBottom className="paddingtophalfcm">
          {t("ContactUs.ES")}{":"}
       </Typography>
      <Link
          href={contriesLink.es}
          className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-caption MuiTypography-colorInherit"
          id="contactUsLink"
          color="inherit"
          variant="caption"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecorationLine: "underline", color: "blue" }}>
          <Typography variant="caption">
              Únase a nuestro programa de alquiler para empresas
          </Typography>
          <Box component="span" className="visuallyHidden">
            <Typography variant="caption">
              {!languageCode
                ? ",opens new window"
                : t("Footer.OpenNewWindow")}
            </Typography>
          </Box>
      </Link>
      <Typography variant="body2"  gutterBottom className="paddingtophalfcm">
         {t("ContactUs.US")}{":"}
      </Typography>
      <Link
          href={contriesLink.us}
          className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-caption MuiTypography-colorInherit"
          id="contactUsLink"
          color="inherit"
          variant="caption"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecorationLine: "underline", color: "blue" }}>
          <Typography variant="caption">
            Join our Business Rental Programme
          </Typography>
          <Box component="span" className="visuallyHidden">
            <Typography variant="caption">
              {!languageCode
                ? ",opens new window"
                : t("Footer.OpenNewWindow")}
            </Typography>
          </Box>
     </Link> 
      <Typography variant="body2"  gutterBottom className="paddingtophalfcm">
         {t("ContactUs.CA")}{":"}
      </Typography>
     
      <Link
        href={contriesLink.ca}
        className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-caption MuiTypography-colorInherit"
        id="contactUsLink"
        color="inherit"
        variant="caption"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecorationLine: "underline", color: "blue" }}>
        <Typography variant="caption">
          Join our Business Rental Programme
        </Typography>
        <Box component="span" className="visuallyHidden">
          <Typography variant="caption">
            {!languageCode
              ? ",opens new window"
              : t("Footer.OpenNewWindow")}
          </Typography>
        </Box>
      </Link>
      </div>
    </div>
  );
};

return (
  <Grid container spacing={3}>
    <Grid item xs={12}>
    <Typography variant="h1"   style={{ textDecorationLine: "underline" , fontWeight: "bold", fontSize: "18px"}}  gutterBottom>
        {t("Footer.ContactUs")}
    </Typography>
    </Grid>
    {/* Technical Issue Section */}
    <Grid item xs={6}>
    <Typography variant="h2" style={{ fontWeight: "bold", fontSize: "14px"}}  gutterBottom>
        {t("ContactUs.ContactUsTechnicalIssue")}
    </Typography>
    <Typography className="paddingLefthalfcm" variant="body2" gutterBottom>
         {t("ContactUs.ContactUsTechnicalIssueText")}
         {" "}
    </Typography>
    <Link
        href={userGuideURL}
        className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-caption MuiTypography-colorInherit"
        id="contactUsLink"
        color="inherit"
        variant="caption"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecorationLine: "underline", color: "blue" }}>
        <Typography variant="caption">
         FAQ
        </Typography>
        <Box component="span" className="visuallyHidden">
          <Typography variant="caption">
            {!languageCode
              ? ",opens new window"
              : t("Footer.OpenNewWindow")}
          </Typography>
        </Box>
      </Link>{". "}
    <Divider orientation="horizontal" flexItem sx={{ borderRightWidth: 2, borderStyle: "dashed", borderWidth: 1 }}/>
    <Typography  variant="h3" className="paddingtophalfcm"  sx={{textDecoration:"underline"}} gutterBottom>
      <b>{t("ContactUs.ContactUsEurope")}</b>
    </Typography>
    <Typography variant="body2" gutterBottom>
         {t("ContactUs.ContactUsEuropeQRSupportText")}
         {": "}
    </Typography>
    {GUSQRDetailsTable(t)}
    <Typography variant="body2" gutterBottom>
    {t("ContactUs.ContactUsEuropeHelpDeskText")}
    {": "}
    </Typography>
    
    {ContactDetailsTable(t)}
    <br/>
    <Divider orientation="horizontal" flexItem sx={{ borderRightWidth: 2, borderStyle: "dashed", borderWidth: 1 }}/>

    <Typography  variant="h3" className="paddingtophalfcm"  sx={{textDecoration:"underline"}} gutterBottom>
    <b>{t("ContactUs.ContactUsNorthAmerica")}</b>
    </Typography>
    <Typography variant="body2" gutterBottom>
        {t("ContactUs.ContactUsNorthAmericaSupportText")}
        {": "}
    </Typography>
    {NorthAmericaContactDetailsTable(t)}
    </Grid>

    <Divider orientation="vertical" flexItem sx={{ borderRightWidth: 2 , paddingRight: 2 }}/>

    {/* Account Enquiry Section */}
    <Grid item xs={5}>
    <Typography variant="h2" style={{ fontWeight: "bold", fontSize: "14px"}}  gutterBottom>
          {t("ContactUs.ContactUsBusinessAccountInquiry")}
      </Typography>
      <Typography className="paddingLefthalfcm" variant="body2" gutterBottom>
          {t("ContactUs.ContactUsBusinessAccountInquiryText")}{": "}
      </Typography>
      <Typography>
      {BusinessEnquiryTable(t)}
      </Typography>
    </Grid>

   {/* Reservation Query Section */}
    <Grid item xs={12}>
      <Typography variant="h2" className="padding0topbody" gutterBottom>
          <b>{t("ContactUs.ContactUsReservationQuery")}</b>
      </Typography>
      <Typography variant="body2" gutterBottom>
         {t("ContactUs.ContactUsReservationQueryText")}
         {" "}
      </Typography>
    </Grid>
  </Grid>
);

};
export default LegalContactUs;
