import React, { useEffect, useState } from "react";
import { updateReservation } from "components/store/actions/reservation/reservationActions";
import {
  Box,
  Grid,
  Typography,
  Button,
  Backdrop,
  CircularProgress 
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import {
  useStyles,
  AccordionSummary,
  AccordionDetails,
} from "../../../shared/styles/common";
import dompurify from "dompurify";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { broadcastAnnouncement } from "components/store/actions/shared/announcementActions";
import { AppState } from "components/store/reducers/rootReducer";
import { ReservationProps } from "../../reservationType";
import { registerLocale } from "react-datepicker";
import axiosInstance from "helpers/axiosInstance";
import de from "date-fns/locale/de";
import es from "date-fns/locale/es";
import fr from "date-fns/locale/fr";
import { useDispatch, useSelector } from "react-redux";
import {  ManualCardActivityResult, ManualCardStartActivity, ManualCardStartActivityInitialState } from "./paymentType";
import "react-datepicker/dist/react-datepicker.css";
import CompactJourneyDetailsView from "../Shared/compactJourneyDetailsView";

import {
  clearAlertMessage,
  showErrorAlertMessage    
} from "components/store/actions/shared/alertActions";


import { useTranslation } from "react-i18next";

import { authService } from "services/authService";
import _ from "lodash";

import { getApiOrigin } from "helpers/history";
import McbpsActivityResult from "./mcbpsActivityResult";
import { getCardBrand, getCardExpiry, getMaskedCardNumber, isCreditCardExpiryValid } from "helpers/creditCard";
import useReservationMode, { ReservationMode } from "helpers/useReservationMode";


const PaymentForm: React.FC<ReservationProps> = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const IsDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  const [loading, setLoading] = React.useState(false);
  const [showIframe, setShowIframe] = React.useState(false);
  const [frameHeight, setFrameHeight] = React.useState(600);
  const [showResult, setShowResult] = React.useState(false);
  
  const [errorMessage, setErrorMessage] = React.useState("");
  const [canContinue, setCanContinue] = React.useState(false);
  const apiOrigin = getApiOrigin();
  let { reservation } = useSelector((state: AppState) => state.dailyRental);
  
  const [paymentContext, setPaymentContext] = useState<ManualCardStartActivity>(ManualCardStartActivityInitialState);    
  const [paymentResult, setPaymentResult] = useState<ManualCardActivityResult | null>(null);       
  const [resMode] = useReservationMode();

  

 const user = authService.getUserObject();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const titleRef = React.useRef<HTMLDivElement>(null);
  const sanitizedHtml = dompurify.sanitize(t("lblSecureConnection", {usrlocale : authService.getUserLocale()}),  { ADD_ATTR: ['target'] });

  registerLocale("de", de);
  registerLocale("es", es);
  registerLocale("fr", fr);
  
  
  const handler = async event => {    
    if(event.data.toString().includes("new_height"))
      {        
        let height = event.data.toString().split(":")[1];        
        setFrameHeight(height);
        return;
      } 
    if(event.origin !== apiOrigin) return;
    let data = JSON.parse(event.data.toString());
    console.log("Message Data:", data);
    console.log("AcitivityUrn: ", paymentContext?.activityUrn);
    if(data.success === "success"){
    await finalizePayment(data.success, data.sessionId);
    }
    else
    {
      console.log("Error in Iframe confirmation:");     
      dispatch(showErrorAlertMessage(t("UnexpectedError")));
      dispatch(broadcastAnnouncement(t("UnexpectedError")));  
      setShowIframe(false);
      setCanContinue(false);
      setPaymentResult(null);
      setShowResult(true);   
    }
 
}

const ConvertToPaymentResult = (dto) : ManualCardActivityResult| null => {
if(dto)
{
  return  {
    data : { cardDetails: {
      nameProvided: dto.cardBrand,
      maskedCardNumber: dto.cardLastDigits,
      expiration: {
        lastValidMonth: dto.expiryMonth.toString(),
        year: dto.expiryYear.toString(),
      }
    },
      cardAttributes: {
        cardBrand: dto.cardBrand,
        cardIssuer: dto.cardIssuer, 
      } 
     },
     errors : "",    
     success : true
      
  }
}
return null;
}
  useEffect(() => {
    console.log("Passed Reservation from Redux: ", reservation);
    dispatch(clearAlertMessage());
    const getMcbpsFrame = async () => {
      await getMcbpsFrameData();
    }   
    getMcbpsFrame();
      
  }, []);
  
  useEffect(() => {
    console.log("Payment Context Changed: ", paymentContext);
    window.addEventListener("message", handler)
    
    // clean up
    return () => window.removeEventListener("message", handler)
  }, [paymentContext]);

  useEffect(() => {
    console.log("Payment Result: ", paymentResult);
    
  }, [paymentResult]);

  const getMcbpsFrameData = async () => {
    setLoading(true);
    setShowResult(false);
    setCanContinue(false);
    if(resMode === ReservationMode.Edit)
    {
      await axiosInstance
      .get("/paymentstore/details/" + reservation?.reservationNumber?.number)
      .then((res) => {
        console.log("Existing Payment Card Details: ", res.data);
        if (res.data) 
        {
          let resultData = ConvertToPaymentResult(res.data);          
         ValidateRentalPeriod(resultData?.data);
         setPaymentResult(resultData);
         setShowIframe(false);
         setShowResult(true);
        }
        else 
        {
          console.log("Error in getting existing payment details: ", res.data.errors);
          setShowIframe(false);
          let errorlist: string[] = [];
          if (Object.keys(res.data.errors).length > 0) {
            for (const [key, value] of Object.entries(res.data.errors)) {
              if (
                key === "unexpectedError" &&
                Object.keys(res.data.errors).length > 1
              )
                // remove the exception error if there are more than 1 errors in the list
                continue;
              else errorlist.push(value as string);
            }
            dispatch(showErrorAlertMessage(errorlist));
            dispatch(broadcastAnnouncement(errorlist.join(". ")));
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("Error in getting usercount");
        setLoading(false);
      });
    }
    else
    {
    const model = { FirstName : user.firstName, 
      LastName : user.lastName, 
      Email : user.emailAddress, 
      PhoneNumber : "+44123456789", 
      Branch : reservation?.locations?.startLocation.peopleSoftId, 
      Locale : user.isoLanguageCode
    };

    await axiosInstance
      .post("/paymentstore/startactivity", model)
      .then((res) => {
        console.log("Payment Frame Data: ", res.data);
        if (res.data.success) 
        {
         setPaymentContext(res.data);
         setShowIframe(true);
        }
        else 
        {
          console.log("Validation Error", res.data.errors);
          setShowIframe(false);
          let errorlist: string[] = [];
          if (Object.keys(res.data.errors).length > 0) {
            for (const [key, value] of Object.entries(res.data.errors)) {
              if (
                key === "unexpectedError" &&
                Object.keys(res.data.errors).length > 1
              )
                // remove the exception error if there are more than 1 errors in the list
                continue;
              else errorlist.push(value as string);
            }
            dispatch(showErrorAlertMessage(errorlist));
            dispatch(broadcastAnnouncement(errorlist.join(". ")));
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("Error in getting usercount");
        setLoading(false);
      });
    }
  };
 const handleEdit = async() => {    
    setLoading(true);
    await getMcbpsFrameData();
  }
  const finalizePayment = async (status:string, sessionId?:string) => {
     let model =   {status : status, activityUrn : paymentContext?.activityUrn, contextId: paymentContext.contextId, sessionId : sessionId };
     setLoading(true);
     setErrorMessage("");
     console.log("Sending request to finalise activity: ", model);
    await axiosInstance
      .post("/paymentstore/finalizeactivity", model)
      .then((res) => {
        console.log("Finalize Activity success Data: ", res.data);           
        ValidateRentalPeriod(res.data.data);
        setPaymentResult(res.data);
        setShowResult(true);
             
        setShowIframe(false);
        setLoading(false);  
        })
      .catch((err) => {
        console.log("Error in finalize Activity:", err);
        setErrorMessage("Error in getting card details Finalize Activity.");
        dispatch(showErrorAlertMessage(t("UnexpectedError")));
        dispatch(broadcastAnnouncement(t("UnexpectedError")));  
         setLoading(false);
         setShowIframe(false);
         setCanContinue(false);
         setPaymentResult(null);
         setShowResult(true);       
      });
  };

 const ValidateRentalPeriod = (data) =>
  {    
    if(!isCreditCardExpiryValid(data?.cardDetails.expiration.lastValidMonth, data?.cardDetails.expiration.year, reservation?.dateTime?.endDateTime.toString()))
      {        
        setErrorMessage(t(resMode === ReservationMode.Edit ? "ReservationWizard.paymentPage.msgCardExpiryBeforeEndDateForEdit" : "ReservationWizard.paymentPage.msgCardExpiryBeforeEndDate"));
        setCanContinue(false);
      }
      else
      {       
        setCanContinue(true);
        let newReservation = _.cloneDeep(reservation);
        newReservation?.additionalInformation?.additionalInformationFields?.forEach((item:any) => {
          if(item.fieldName.toLowerCase() === "etd_res_id") {
            item.fieldValue = paymentContext.contextId;
            }           
        });

        if(newReservation) 
          newReservation.payment = {activityUrn: paymentContext.activityUrn, contextId: paymentContext.contextId,
        cardExpiry : getCardExpiry(data?.cardDetails.expiration.lastValidMonth.toString(), data?.cardDetails.expiration.year.toString()),
        cardBrand : getCardBrand(data?.cardAttributes.cardBrand.toString()),
        cardNumber : getMaskedCardNumber(data?.cardDetails.maskedCardNumber)
        };

        console.log("Updated Reservation: ", newReservation);
        if(newReservation) {
          dispatch(updateReservation({
            reservation: newReservation,
            hasError: false,
            errors: [],
          }));
        }
        setErrorMessage("");
      }
  }
  return (
    <Box
      boxShadow={3}
      p={3}
      bgcolor="background.paper"
      style={{ width: "100%"}}
    >
      <Grid container>
        <div ref={titleRef} tabIndex={-1} className={classes.focused}>
          <Typography
            id = "paymentPageTitle"
            variant="h1"
            style={{
              marginTop: 0,
              marginBottom: "15px",
            }}
          >
            {t("ReservationWizard.paymentPageTitle")}
          </Typography>
        </div>
        <CompactJourneyDetailsView
          journeyUId={"00-00"}
          journeyDetails={props.journeyDetails}
        />
      </Grid>
      
      <Backdrop
                className={classes.backdrop}
                open={loading }
              >
                <CircularProgress />
      </Backdrop>
      <Grid item xs={12}style={{marginTop:10}}>
        <span id="lblRequiedFields" style={{ fontWeight: "bold", color: "#000000", textAlign:"left" }}>
          {t("lblRequiedFields")}
        </span>
        </Grid>
      <Grid container style={{width:"100%", textAlign:"center",justifyContent: "center", marginTop:10}}>
        
      <Grid item xs={12}  style={{textAlign:"center", marginTop:20}}> 
        <div id="lblSecureConnection" dangerouslySetInnerHTML={{
            __html: sanitizedHtml,
          }}/>
      </Grid>
      <Grid item xs={12} style={{width:"100%", textAlign:"center",justifyContent: "center", marginTop:10}}>
        {showIframe && paymentContext.url && 
            <Accordion style={{ marginLeft: (IsDesktop ? "25%" : "0%"),  width: (IsDesktop ? "50%" : "100%") }}  defaultExpanded  id="sectionResNumber"  >
            <h2>
              <AccordionSummary                
                aria-controls="panel-addpayment"
                id="addPayment"
                className={classes.heading}
              >
                {t(
                  "ReservationWizard.paymentPage.labelSectionAddPayment"
                )}
              </AccordionSummary>
            </h2>
            <AccordionDetails>
              <iframe id="marketpayframe" src={paymentContext.url} title="Test" 
              width={"100%"} height={frameHeight}     
              style = {{border:"0px"}}                    
              sandbox='allow-popups allow-scripts allow-same-origin allow-forms'></iframe> 
          
            </AccordionDetails>  
            </Accordion>
              } 
         </Grid>
         <Grid item xs={12} style={{textAlign:"center", width:"100%"}}>
          {showResult && paymentResult && 
           <div>            
             <McbpsActivityResult data={paymentResult?.data} errors = {errorMessage} success={true} handleEdit={handleEdit} hideEdit={resMode === ReservationMode.Edit} />
             </div>
           
          }
        </Grid>
      
    
      </Grid>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "1em",
        }}
      >
        <Button
          id="btnBack"
          color="primary"
          aria-label={t("lblBackToPreviousStep")}
          variant="contained"
          onClick={() => {
            dispatch(clearAlertMessage());
            if (props.handleBack) props.handleBack(0, 0);
          }}
        >
          {t("btnBack")}
        </Button>
        <Button
          id="btnSubmit"
          color="primary"
          variant="contained"
          aria-label={t("lblContinueToNextStep")}
          onClick={() => {
            dispatch(clearAlertMessage());
            if (props.handleNext) props.handleNext(0, 0);
          }}
          disabled={!canContinue}
        >
          {t("btnContinue")}
        </Button>
      </div>
    </Box>
  );
};
export default PaymentForm;

