import React from "react";
import { appInsights } from "helpers/appInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-common";
import { broadcastAnnouncement } from "../components/store/actions/shared/announcementActions";
import {
  showErrorAlertMessage,
} from "../components/store/actions/shared/alertActions";
import Grid from "@mui/material/Grid";
import  Divider from "@mui/material/Divider";
import { Box, Link, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import "../views/general.css";
import { authService } from "services/authService";
import { useTranslation } from "react-i18next";
import axiosInstance from "helpers/axiosInstance";
import { browsersLanugage } from "helpers/localization";
import { useDispatch } from "react-redux";
import { clearAlertMessage } from "../components/store/actions/shared/alertActions";
import configParams from "../configParams";
import QRCode from "Assets/Images/GUSQRCode.jpg";

interface ContactInfo {
  AccountManagerName: string;
  AccountManagerEmailAddress: string;
  AccountManagerPhone: string;

  CompanyContactName: string;
  CompanyContactEmailAddress: string;
  CompanyContactPhone: string;
  CustomerServiceName: string;
  CustomerServiceEmailAddress: string;
  CustomerServicePhone: string;
}

const ContactUs = (props) => {
  const [contactInfo, setcontactInfo] = React.useState<null | ContactInfo>();

  const { t } = useTranslation();
  const isUserAuthenticated = authService.isAuthenticated();
  var languageCode = isUserAuthenticated ? authService.getUserLocale() : browsersLanugage();

  const baseURL = window.location.hostname.endsWith("etd.enterprise.co.uk")
    ? configParams.API_BASE_URL
    : configParams.API_BASE_URL_EHI;
  const userGuideURL = baseURL + "/userGuide";
  const qrLink = "https://d36ver3o3fxb7y.cloudfront.net/gus.html?chat-source=field"

  const dispatch = useDispatch();

  async function loadCompanyContact() {
    let userInfo = authService.getUserInfo();
    let companyUId = userInfo["companyUId"];

    await axiosInstance
      .get(`/company/contactus/${companyUId}`)
      .then((response) => {
        if (response.data.success) {
          setcontactInfo(response.data.data);
        }
      })
      .catch((error) => {
        if (
          error.response != null &&
          error.response.status != null &&
          error.response.status === 400
        ) {
          console.error("There is an error", error);
        } else console.error("There is an error", error);
        appInsights.trackException({id: "Get Company Contactus", exception: error, severityLevel: SeverityLevel.Error});
      });
  }

  React.useEffect(() => {
    dispatch(clearAlertMessage());
    document.title = "Enterprise Rent-A-Car - CONTACT US";
  });

  React.useEffect(() => {
    loadCompanyContact();
  }, [props]);
  const ContactDetailsTable = (t) => {
    return (
      <div>
        <TableContainer id="conttactTable" aria-label="Table displaying UK and IE phone contact">
        <Table role="table" style={{ width: "auto", tableLayout: "auto" }}>
          <TableBody style={{width: "100%"}}>
          <TableRow role="row">
                <TableCell role="cell" width="6%" 
                         style={{
                          padding: "0px", borderBottom: "none",
                          fontWeight: "bold", border: "0px solid green", paddingLeft:25
                          }}
                         align="left">
                    <span style={{ marginRight: "8px" }}>•</span>
                    {t("ContactUs.UK")}
                  </TableCell>
                <TableCell
                  width="20%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left">
                    +44 800 783 9445
                </TableCell>
              </TableRow>
              <TableRow role="row">
                <TableCell role="cell" width="6%" 
                         style={{
                          padding: "0px", borderBottom: "none",
                          fontWeight: "bold", border: "0px solid green", paddingLeft:25
                          }}
                         align="left">
                    <span style={{ marginRight: "8px" }}>•</span>
                    {t("ContactUs.IE")}
                  </TableCell>
                <TableCell
                  width="20%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left">
                    +353 1800554469
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };
  
  const NorthAmericaContactDetailsTable = (t) => {
    return (
      <div>
        <TableContainer id="conttactNorthAmericaTable" aria-label="table North America phone contacts">
        <Table  role="table" style={{ width: "auto", tableLayout: "auto" }}>
            <TableBody style={{width: "100%"}}>
            <TableRow role="row">
                  <TableCell role="cell" width="6%" 
                           style={{
                            padding: "0px", borderBottom: "none",
                            fontWeight: "bold", border: "0px solid green", paddingLeft:25
                            }}
                           align="left">
                      <span style={{ marginRight: "8px" }}>•</span>
                      {t("ContactUs.US")}
                    </TableCell>
                  <TableCell
                    width="20%"
                    style={{
                      padding: 0,
                      borderBottom: "none",
                      border: "0px solid green",
                    }}
                    align="left">
                      +1-855-573-0012
                  </TableCell>
                </TableRow>
                <TableRow role="row">
                  <TableCell role="cell" width="6%" 
                           style={{
                            padding: "0px", borderBottom: "none",
                            fontWeight: "bold", border: "0px solid green", paddingLeft:25
                            }}
                           align="left">
                      <span style={{ marginRight: "8px" }}>•</span>
                      {t("ContactUs.CA")}
                    </TableCell>
                  <TableCell
                    width="20%"
                    style={{
                      padding: 0,
                      borderBottom: "none",
                      border: "0px solid green",
                    }}
                    align="left">
                      +1-855-573-0012
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
    );
  };

  const QRDetailsTable = (t) => {
    return (
      <div>
        <TableContainer id="conttactGUSTable" aria-label="table field support">
          <Table role="table" style={{ width: "auto", tableLayout: "auto" }}>
          <TableBody style={{width: "100%"}}>
          <TableRow role="row">
                  <TableCell role="cell" width="10%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    fontWeight: "bold",
                    border: "0px solid green",
                  }}
                  align="left">
                <img
                  style={{
                    display: "block",
                    maxWidth: "80%",
                    maxHeight: "20%",
                    marginBottom: "10px",
                    paddingLeft: "5px"
                  }}
                  alt="QR Code"
                  id={"QRCodeImage"}
                  src={QRCode}
                />
                  </TableCell>
                <TableCell
                  width="20%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left">
    
                <Link
                    href={qrLink}
                    className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-caption MuiTypography-colorInherit"
                    id="contactUsLink"
                    color="inherit"
                    variant="caption"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecorationLine: "underline", color: "blue" }}>
                    <Typography variant="caption">
                    {t("ContactUs.ContactUsQRText")}
                    </Typography>
                    <Box component="span" className="visuallyHidden">
                      <Typography variant="caption">
                        {!languageCode
                          ? ",opens new window"
                          : t("Footer.OpenNewWindow")}
                      </Typography>
                    </Box>
                </Link>
                    <div>({t("ContactUs.ContactUsOnlyEuropeText")}) </div>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  const AccountEnquiryTable = (t) => {
    return(
      <div>
      <div className="paddingLefthalfcm paddingbottomhalfcm paddingtophalfcm body2">
      <TableContainer  aria-label="Account enquiry company table">
        <Table role="table">
        <TableBody style={{width: "100%"}}>
        <TableRow role="row">
            <TableCell role="cell" style={{ padding: 0, borderBottom: "none", width:"40%" }} align="left">
                {t("ContactUs.CompanyContact")}{": "}
            </TableCell>
            <TableCell role="cell" style={{ padding: 0, borderBottom: "none" }} align="left">
                {contactInfo ? contactInfo["companyContactName"] : ""}
            </TableCell>
            </TableRow>
            <TableRow role="row" style={{ height: 5 }}>
              <TableCell role="cell" style={{ padding: 0, borderBottom: "none" }} align="left">
              {t("ContactUs.EmailAddress")}{": "}
              </TableCell>
              <TableCell  role="cell" style={{ padding: 0, borderBottom: "none" }} align="left">
                  <Link
                     href={
                      "mailto:" +
                      (contactInfo
                        ? contactInfo["companyContactEmailAddress"]
                        : "")}
                    className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-caption MuiTypography-colorInherit"
                    id="contactUsLink"
                    color="inherit"
                    variant="caption"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecorationLine: "underline", color: "blue" }}>
                    <Typography variant="caption">
                    {" "}
                    {contactInfo
                      ? contactInfo["companyContactEmailAddress"]
                      : ""}
                </Typography>
                <Box component="span" className="visuallyHidden">
                  <Typography variant="caption">
                    {!languageCode
                      ? ",opens new window"
                      : t("Footer.OpenNewWindow")}
                  </Typography>
                </Box>
      </Link>
         </TableCell>
            </TableRow>
            <TableRow role="row" style={{ height: 5 }}>
              <TableCell role="cell" style={{ padding: 0, borderBottom: "none" }} align="left">
               {t("ContactUs.Phone")}{": "}
              </TableCell>
              <TableCell role="cell" style={{ padding: 0, borderBottom: "none" }} align="left">
                {contactInfo ? contactInfo["companyContactPhone"] : ""}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      </div>
      <div className="paddingLefthalfcm paddingbottomhalfcm body2">
       <TableContainer aria-label="Account enquiry enterprise contact table">
            <Table  role="table">
            <TableBody style={{width: "100%"}}>
            <TableRow role="row">
              <TableCell role="cell" style={{ padding: 0, borderBottom: "none", width:"40%" }} align="left">
              {t("ContactUs.EnterpriseContact")}{": "}
              </TableCell>
                  <TableCell role="cell" style={{ padding: 0, borderBottom: "none" }} align="left">
                    {contactInfo ? contactInfo["accountManagerName"] : ""}
                  </TableCell>
                </TableRow>
                <TableRow role="row" style={{ height: 5 }}>
                  <TableCell role="cell" style={{ padding: 0, borderBottom: "none" }} align="left">
                    {t("ContactUs.EmailAddress")}{": "}
              </TableCell>
                  <TableCell role="cell" style={{ padding: 0, borderBottom: "none" }} align="left">
                    <Link
                        href={ "mailto:" + (contactInfo ? contactInfo["accountManagerEmailAddress"]: "")}
                        className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-caption MuiTypography-colorInherit"
                        id="contactUsLink"
                        color="inherit"
                        variant="caption"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecorationLine: "underline", color: "blue" }}>
                        <Typography variant="caption">
                        {" "}
                        {contactInfo ? contactInfo["accountManagerEmailAddress"]: ""}                    </Typography>
                        <Box component="span" className="visuallyHidden">
                          <Typography variant="caption">
                            {!languageCode
                              ? ",opens new window"
                              : t("Footer.OpenNewWindow")}
                          </Typography>
                        </Box>
                </Link>
                  </TableCell>
                </TableRow>
                <TableRow role="row" style={{ height: 5 }}>
                <TableCell role="cell" style={{ padding: 0, borderBottom: "none" }} align="left">
               {t("ContactUs.Phone")}{": "}
              </TableCell>
                  <TableCell role="cell" style={{ padding: 0, borderBottom: "none" }} align="left">
                    {contactInfo ? contactInfo["accountManagerPhone"] : ""}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
      </div>
      <div className="paddingLefthalfcm paddingbottomhalfcm body2">
          <TableContainer aria-label="Account enquiry customer service contact table">
          <Table role="table" >
          <TableBody style={{width: "100%"}}>
          <TableRow  role="row">
                <TableCell role="cell" style={{ padding: 0, borderBottom: "none", width:"40%" }} align="left">
                {t("ContactUs.CustomerServiceContact")}{": "}
                </TableCell>
                  <TableCell role="cell" style={{ padding: 0, borderBottom: "none" }} align="left">
                    {contactInfo ? contactInfo["customerServiceName"] : ""}
                  </TableCell>
                </TableRow>
                <TableRow role="row">
                  <TableCell role="cell" style={{ padding: 0, borderBottom: "none" }} align="left">
                  {t("ContactUs.EmailAddress")}{": "}
                  </TableCell >
                  <TableCell role="cell" style={{ padding: 0, borderBottom: "none" }} align="left">
                    <Link
                        href={"mailto:" + (contactInfo? contactInfo["customerServiceEmailAddress"]: "")}
                        className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-caption MuiTypography-colorInherit"
                        id="contactUsLink"
                        color="inherit"
                        variant="caption"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecorationLine: "underline", color: "blue" }}>
                        <Typography variant="caption">
                        {" "}
                        {contactInfo? contactInfo["customerServiceEmailAddress"]: ""}
                        </Typography>
                        <Box component="span" className="visuallyHidden">
                          <Typography variant="caption">
                            {!languageCode
                              ? ",opens new window"
                              : t("Footer.OpenNewWindow")}
                          </Typography>
                        </Box>
                      </Link>
                  </TableCell>
                </TableRow>
                <TableRow role="row">
                <TableCell  role="cell" style={{ padding: 0, borderBottom: "none" }} align="left">
               {t("ContactUs.Phone")}{": "}
              </TableCell>
                  <TableCell role="cell" style={{ padding: 0, borderBottom: "none" }} align="left" >
                    {contactInfo ? contactInfo["customerServicePhone"] : ""}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
      </div>
    </div>
    );
  };
   return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
        <Typography variant="h1"   style={{ textDecorationLine: "underline" , fontWeight: "bold", fontSize: "18px"}}  gutterBottom>
            {t("Footer.ContactUs")}
        </Typography>
        </Grid>
        {/* Technical Issue Section */}
        <Grid item xs={6}>
        <Typography variant="h2" style={{ fontWeight: "bold", fontSize: "14px"}}  gutterBottom>
        {t("ContactUs.ContactUsTechnicalIssue")}
        </Typography>
        <Typography className="paddingLefthalfcm" variant="body2" gutterBottom>
             {t("ContactUs.ContactUsTechnicalIssueText")}
             {" "}

        <Link
        href={userGuideURL}
        className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-caption MuiTypography-colorInherit"
        id="contactUsLink"
        color="inherit"
        variant="caption"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecorationLine: "underline", color: "blue" }}>
        <Typography variant="caption">
         FAQ
        </Typography>
        <Box component="span" className="visuallyHidden">
          <Typography variant="caption">
            {!languageCode
              ? ",opens new window"
              : t("Footer.OpenNewWindow")}
          </Typography>
        </Box>
      </Link>{". "}
      </Typography>

        <Divider orientation="horizontal" flexItem sx={{ borderRightWidth: 2, borderStyle: "dashed", borderWidth: 1 }}/>
        <Typography  variant="h3" className="paddingtophalfcm"  sx={{textDecoration:"underline"}} gutterBottom>
          <b>{t("ContactUs.ContactUsEurope")}</b>
        </Typography>
        <Typography variant="body2" gutterBottom>
             {t("ContactUs.ContactUsEuropeQRSupportText")}
             {":"}
        </Typography>
        {QRDetailsTable(t)}
        <Typography variant="body2" gutterBottom>
        {t("ContactUs.ContactUsEuropeHelpDeskText")}
        {": "}
        </Typography>
        {ContactDetailsTable(t)}
         <br/>
        <Divider orientation="horizontal" flexItem sx={{ borderRightWidth: 2, borderStyle: "dashed", borderWidth: 1 }}/>

        <Typography variant="h3" className="paddingtophalfcm"  sx={{textDecoration:"underline"}} gutterBottom>
        <b>{t("ContactUs.ContactUsNorthAmerica")}</b>
        </Typography>
        <Typography variant="body2" gutterBottom>
            {t("ContactUs.ContactUsNorthAmericaSupportText")}{": "}
        </Typography>
        {NorthAmericaContactDetailsTable(t)}
        </Grid>

        <Divider orientation="vertical" flexItem sx={{ borderRightWidth: 2 , paddingRight: 2 }}/>

        {/* Account Enquiry Section */}
        <Grid item xs={5}>
        <Typography variant="h2" style={{ fontWeight: "bold", fontSize: "14px"}}  gutterBottom>
        {t("ContactUs.ContactUsAccountInquiry")}
          </Typography>
          <Typography className="paddingLefthalfcm" variant="body2" gutterBottom>
              {t("ContactUs.ContactUsAccountInquiryText")}{": "}
          </Typography>
          <Typography>
          {AccountEnquiryTable(t)}
          </Typography>
        </Grid>

       {/* Reservation Query Section */}
        <Grid item xs={12}>
          <Typography variant="h2" className="padding0topbody" gutterBottom>
              <b>{t("ContactUs.ContactUsReservationQuery")}</b>
          </Typography>
          <Typography variant="body2" gutterBottom>
             {t("ContactUs.ContactUsReservationQueryText")}
             {" "}
          </Typography>
        </Grid>
      </Grid>
    );
  
};
export default ContactUs;
