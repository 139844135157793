import React, { useEffect, useState, useCallback, ChangeEvent } from "react";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

import { ReservationProps } from "../../reservationType";
import Accordion from "@mui/material/Accordion";
import { driverDetailsValidationSchema } from "./driverDetailsValidationSchema";

import {
  resInitialValue,
  DriverViewModel,
  DriverDeclaration,
  LoyaltyProgramType,
  driverViewModelInit,
} from "../Reservation/reservationFormType";

import { Formik, Form, Field } from "formik";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { appInsights } from "helpers/appInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-common";
import {
  DriverDetailsViewModel,
  driverInitialValue,
} from "./driverDetailsType";
import axiosInstance from "helpers/axiosInstance";

import { useDispatch, useSelector } from "react-redux";
import { AppState } from "components/store/reducers/rootReducer";

import "react-datepicker/dist/react-datepicker.css";
import useDriverDetails from "./useDriverDetails";
import {
  clearAlertMessage,
  showErrorAlertMessage,
} from "components/store/actions/shared/alertActions";

import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { broadcastAnnouncement } from "components/store/actions/shared/announcementActions";
import { authService } from "services/authService";

import BOBOSomeone from "components/shared/BOBOSomeone/BOBOSomeone";
import DriverDetails from "components/shared/DriverDetails/DriverDetails";

import { getEnumKeyByEnumValue } from "helpers/enumMapping";
import {
  useStyles,
  AccordionSummary,
  AccordionDetails,
} from "../../../shared/styles/common";
import { updateDriverDetails } from "components/store/slicers/driverDetailSlice";
import useReservationMode, {
  ReservationMode,
} from "helpers/useReservationMode";
import { resetReservation } from "components/store/actions/reservation/reservationActions";
//import BBCDriverDetails from "components/shared/bbcDriver/bbcDriverDetails";
import CompactJourneyDetailsView from "../Shared/compactJourneyDetailsView";

const isDriverSavedInCache = (initialDriver) => {
  return (
    initialDriver &&
    initialDriver.driver.firstName !== "" &&
    initialDriver.driver.lastName !== "" &&
    initialDriver.driver.email !== ""
  );
};



const DriverDetailsForm: React.FC<ReservationProps> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [resMode] = useReservationMode();
  const [isBoboEnabled, isLoyaltyEnabled, isBBCDriver] = useDriverDetails();
  const [bookForMyself, setBookForMyself] = useState(true);
  const [data, setData] = useState<DriverDetailsViewModel>(driverInitialValue);

  const [driverViewModel, setDriverViewModel ] =useState <DriverViewModel>(driverViewModelInit);
  let existingDriver = useSelector((state: AppState) => state.driverDetail);

  const isDriverExists = isDriverSavedInCache(existingDriver);
  
  const GetCountryCodeBasedOnStartLocation = async (location: string) => {
    let result = await axiosInstance
      .get(`places/autocomplete?filter=${encodeURIComponent(location)}`)
      .then(async (response) => {
        if (response.data.success) {
          let stationId = response.data.data.data[0]?.stationId;
          let res2 = await axiosInstance.get(`places/${stationId}`);
          if (res2) {
            return res2.data.data.countryCode;
          }
        }
        else
        {
          console.warn("Error in response:", response.data.messages);
        }
      })
      .catch((error) => {
        console.log("Error in getting google Api data: ", location);
            appInsights.trackException({
              exception: error,
              properties: {
                method: "autocomplete",
                Component: "AddressLookup",
              },
              severityLevel: SeverityLevel.Error,
            });
            console.log(error);
        return null;
      });
  
    return result;
  };
  const [additionalDrivers, setAdditionalDrivers] = useState<
    DriverDeclaration[]
  >(resInitialValue.additionalDriver);

  const [isValidAdditionalDrivers, setIsValidAdditionalDrivers] =
    useState<boolean>(true);

  const locale = authService.getUserLocale();
  const driverInfo = authService.getDriverInfo();
  const [primaryDriverDetailsToggle, setPrimaryDriverDetailsToggle] =
    useState<boolean>(false);
  const titleRef = React.useRef<HTMLDivElement>(null);
  const driverformRef = React.useRef<any>(null);
  let journeyProfile = useSelector((state: AppState) => state.journeyProfile);
  
  const [isConsentCountry, setIsConsentCountry] = React.useState(false);

  const theme = useTheme();
  const isSmXs = useMediaQuery(theme.breakpoints.down("lg"));

  const updateLoyaltyWithDefaultValue = (loyaltyValue: any) => {
    const booker = authService.getBookerDetails();
    //both loyalty values are set in profile so default would be not selected
    if (
      booker.savedEnterprisePlusNumber &&
      booker.savedEnterprisePlusNumber?.length > 0 &&
      booker.savedEmeraldClubNumber &&
      booker.savedEmeraldClubNumber.length > 0
    )
      return loyaltyValue;

    //if EnterprisePlusNumber is selected
    if (
      booker.savedEnterprisePlusNumber &&
      booker.savedEnterprisePlusNumber?.length > 0
    ) {
      let newLoyaltyValue = {
        ...loyaltyValue,
        loyaltyId: booker.savedEnterprisePlusNumber,
        selectedLoyaltyProgramValue: LoyaltyProgramType.ET,
      };
      return newLoyaltyValue;
    }

    //if savedEmeraldClubNumber is selected
    if (
      booker.savedEmeraldClubNumber &&
      booker.savedEmeraldClubNumber?.length > 0
    ) {
      let newLoyaltyValue = {
        ...loyaltyValue,
        loyaltyId: booker.savedEmeraldClubNumber,
        selectedLoyaltyProgramValue: LoyaltyProgramType.ZL,
      };
      return newLoyaltyValue;
    }
    return loyaltyValue;
  };
  const updateLoyaltyForBOBOUser = (
    emeraldClubNumber: string | undefined,
    enterprisePlusNumber: string | undefined
  ) => {
    //only for new reservation
    if (resMode === ReservationMode.Edit) return;
    //both loyalty values are set in profile so default would be not selected
    if (
      (!emeraldClubNumber && !enterprisePlusNumber) ||
      (emeraldClubNumber && enterprisePlusNumber)
    ) {
      
      driverformRef.current.setFieldValue("loyalty.loyaltyId", "");
      driverformRef.current.setFieldValue(
        "loyalty.selectedLoyaltyProgramValue",
        LoyaltyProgramType.pleaseSelect
      );
      return;
    }

    //if EnterprisePlusNumber is selected

    if (enterprisePlusNumber && enterprisePlusNumber.length > 0) {
     
      driverformRef.current.setFieldValue(
        "loyalty.loyaltyId",
        enterprisePlusNumber
      );
      driverformRef.current.setFieldValue(
        "loyalty.selectedLoyaltyProgramValue",
        LoyaltyProgramType.ET
      );
    }

    //if savedEmeraldClubNumber is selected
    if (emeraldClubNumber && emeraldClubNumber.length > 0) {
     
      driverformRef.current.setFieldValue(
        "loyalty.loyaltyId",
        emeraldClubNumber
      );
      driverformRef.current.setFieldValue(
        "loyalty.selectedLoyaltyProgramValue",
        LoyaltyProgramType.ZL
      );
    }
  };
  const getDriverDetails = useCallback(async (id, restype) => {
    setIsBusy(true);
    if (isDriverExists) {
      setBookForMyself(!existingDriver.driver.isBOBOBooking);
      setData(existingDriver);
    } else {
      let url = `dailyrental/driverdetails/${id}`;
      await axiosInstance
        .get(url)
        .then(async (response) => {
          console.log("DriverDetails data: ", response.data.data);
          let dataReceived = response.data.data;
          dataReceived.isValidDriver = false;
          dataReceived.isValidLoyalty = true;
          if (resMode === ReservationMode.Rebook)
            // for rebook mode make the consent unchecked
            dataReceived.driver.isConsent = false;
          setBookForMyself(!dataReceived.driver.isBOBOBooking);
          if (resMode === ReservationMode.New && isLoyaltyEnabled) {
            const updatedLoyalty = updateLoyaltyWithDefaultValue(
              dataReceived.loyalty
            );
            dataReceived.loyalty = updatedLoyalty;
          }
          setData(dataReceived);
        })
        .catch((error) => {
          console.error("There was an error!", error);
          appInsights.trackException({
            exception: error,
            properties: {
              method: "driverdetails",
              Component: "DR Driver details",
            },
            severityLevel: SeverityLevel.Error,
          });
        });
    }
    await getIsConsentCountry();
    setIsBusy(false);
  }, []);

  const getIsConsentCountry = async () => {
    let countryCode: string | null = null;
    if (journeyProfile.journeyProfile.startLocation.countryCode)
      countryCode = journeyProfile.journeyProfile.startLocation.countryCode;
    else
      countryCode = await GetCountryCodeBasedOnStartLocation(
        journeyProfile.journeyProfile.startLocation.location
      );

    if (countryCode) {
      await axiosInstance
        .get(`/reservation/country/${countryCode}`)
        .then((response) => {
          if (response.data.success) {
            setIsConsentCountry(response.data.data);
          }
        })
        .catch((error) => {
          console.error(error);
          setIsConsentCountry(false);
        });
    }
  };

  useEffect(() => {
    dispatch(clearAlertMessage());
    getDriverDetails(props.journeyUId, 0);
  }, [getDriverDetails, props.journeyUId]);

  const resetDriverDetails = (isBookForMySelf: boolean) => {
    const booker = authService.getBookerDetails();
    console.log("this jsujs happend", booker);

    driverformRef.current.setFieldValue("driver.userTitle.selectedValue", "");
    driverformRef.current.setFieldValue(
      "driver.firstName",
      isBookForMySelf ? booker.firstName : ""
    );

    driverformRef.current.setFieldValue(
      "driver.lastName",
      isBookForMySelf ? booker.lastName : ""
    );

    driverformRef.current.setFieldValue(
      "driver.emailAddress",
      isBookForMySelf ? booker.emailAddress : ""
    );

    driverformRef.current.setFieldValue(
      "driver.contactPhoneNumber.selectedInternationalDiallingCode",
      isBookForMySelf
        ? booker.contactPhoneNumber.selectedInternationalDiallingCode
        : ""
    );
    driverformRef.current.setFieldValue(
      "driver.contactPhoneNumber.number",
      isBookForMySelf ? booker.contactPhoneNumber.number : ""
    );
    driverformRef.current.setFieldValue(
      "driver.alternatePhoneNumber.selectedInternationalDiallingCode",
      isBookForMySelf
        ? booker.alternatePhoneNumber.selectedInternationalDiallingCode
        : ""
    );
    driverformRef.current.setFieldValue(
      "driver.alternatePhoneNumber.number",
      isBookForMySelf ? booker.alternatePhoneNumber.number : ""
    );
    driverformRef.current.setFieldValue(
      "driver.isBOBOBooking",
      !isBookForMySelf
    );
    driverformRef.current.setFieldValue("driver.isPrimaryDriver", true);
    driverformRef.current.setFieldTouched("driver.firstName", true);
    driverformRef.current.setFieldTouched("driver.lastName", true);
    driverformRef.current.setFieldTouched("driver.emailAddress", true);
    // driverformRef.current.setFieldTouched("driver.isBOBOBooking", true);
  };
  const onClickPrimaryDriverDetails = () => {
    setPrimaryDriverDetailsToggle(!primaryDriverDetailsToggle);
  };

  const onDriverDetailsChange = useCallback(
    (driverDetails: DriverViewModel, isValid: boolean) => {
      console.log("Updated Driver Details on Parent Form: ", driverDetails);
      if (isValid) {
        console.log("userTitle:", driverDetails.userTitle);
        driverformRef.current.setFieldValue(
          "driver.userTitle.selectedValue",
          driverDetails.userTitle.selectedValue
        );
        driverformRef.current.setFieldValue(
          "driver.firstName",
          driverDetails.firstName
        );
        driverformRef.current.setFieldValue(
          "driver.lastName",
          driverDetails.lastName
        );
        driverformRef.current.setFieldValue(
          "driver.emailAddress",
          driverDetails.emailAddress
        );
        driverformRef.current.setFieldValue(
          "driver.contactPhoneNumber",
          driverDetails.contactPhoneNumber
        );
        driverformRef.current.setFieldValue(
          "driver.alternatePhoneNumber",
          driverDetails.alternatePhoneNumber
        );
        driverformRef.current.setFieldValue(
          "driver.boboId",
          driverDetails.boboId
        );
        driverformRef.current.setFieldValue(
          "driver.savedEmeraldClubNumber",
          driverDetails.savedEmeraldClubNumber
        );
        driverformRef.current.setFieldValue(
          "driver.savedEnterprisePlusNumber",
          driverDetails.savedEnterprisePlusNumber
        );
        setDriverViewModel(driverDetails);
      }
      driverformRef.current.setFieldValue("isValidDriver", isValid);
      driverformRef.current.setFieldTouched("isValidDriver", true);
      if (!bookForMyself) {
        //for Bobo someone update Loyalty default values
        updateLoyaltyForBOBOUser(
          driverDetails.savedEmeraldClubNumber,
          driverDetails.savedEnterprisePlusNumber
        );
      }
    },
    []
  );

  const resetLoyalty = () => {
    if (isLoyaltyEnabled) {
      driverformRef.current.setFieldValue("loyalty.loyaltyId", "");
      driverformRef.current.setFieldValue(
        "loyalty.selectedLoyaltyProgramValue",
        LoyaltyProgramType.pleaseSelect
      );
      driverformRef.current.setFieldValue("isValidLoyalty", true);
    }
  };

  const updateLoyalty = (
    lastname: string,
    email: string,
    loyalityProgram: string
  ) => {
    const booker = authService.getBookerDetails();

    let savedEnterprisePlusNumber="";
    let savedEmeraldClubNumber="";
    //if driver details match then set the loyalty value if available
    if (bookForMyself){
      savedEnterprisePlusNumber= booker.savedEnterprisePlusNumber?booker.savedEnterprisePlusNumber:"";
      savedEmeraldClubNumber =   booker.savedEmeraldClubNumber ?booker.savedEmeraldClubNumber:"";
    }else {
      savedEnterprisePlusNumber = driverViewModel.savedEnterprisePlusNumber?driverViewModel.savedEnterprisePlusNumber:"";
      savedEmeraldClubNumber =driverViewModel.savedEmeraldClubNumber?driverViewModel.savedEmeraldClubNumber:"";
    }

    //if driver details match then set the loyalty value if available
    // if (
    //   lastname &&
    //   booker.lastName.toLowerCase() === lastname.toLowerCase() 
    // ) {
      switch (loyalityProgram) {
        case LoyaltyProgramType.ET:
          driverformRef.current.setFieldValue(
            "loyalty.loyaltyId",
            savedEnterprisePlusNumber
          );

          break;
        case LoyaltyProgramType.ZL:
          driverformRef.current.setFieldValue(
            "loyalty.loyaltyId",
            savedEmeraldClubNumber
          );
          break;
        default:
          driverformRef.current.setFieldValue("loyalty.loyaltyId", "");
      }
    // } else {
    //   driverformRef.current.setFieldValue("loyalty.loyaltyId", "");
    // }
    var enumKey = getEnumKeyByEnumValue(LoyaltyProgramType, loyalityProgram);
    driverformRef.current.setFieldValue(
      "loyalty.selectedLoyaltyProgramValue",
      loyalityProgram
    );

    driverformRef.current.setFieldValue(
      "loyalty.selectedLoyaltyProgram",
      loyalityProgram === LoyaltyProgramType.pleaseSelect ? "" : enumKey
    );
    driverformRef.current.setFieldValue("loyalty.enabledLoyalty", true);
    driverformRef.current.setFieldTouched(
      "loyalty.selectedLoyaltyProgram",
      true
    );
    driverformRef.current.setFieldTouched(
      "loyalty.selectedLoyaltyProgramValue",
      true
    );
    setTimeout(() => {
      driverformRef.current.setFieldTouched("loyalty.loyaltyId");
    }, 300);
  };

  const isLoyaltyAlreadyValidated = (
    programId: string,   
    lastname: string,
    loyaltyId: string | undefined
  ) => {
    if (programId &&  lastname && loyaltyId) {
      const booker = authService.getBookerDetails();
      let idtoMatch =
        programId === "ET"
          ? booker.savedEnterprisePlusNumber
          : booker.savedEmeraldClubNumber;
      let bookerKey =
        booker.lastName.toLowerCase() +
        "~" +       
        idtoMatch?.toLowerCase();
      let loyaltyKey =
        lastname.toLowerCase() +
        "~" +       
        loyaltyId.toLowerCase();
      return loyaltyKey === bookerKey;
    }
    return true;
  };

  function handleChangeConsent(
    isConsentCountry: ChangeEvent<HTMLInputElement>
  ): void {
    driverformRef.current.setFieldValue(
      "driver.isConsent",
      isConsentCountry.target.checked
    );
  }

  return (
    <Formik
      initialValues={data}
      validateOnChange={true}
      validateOnBlur={true}
      validationSchema={driverDetailsValidationSchema}
      innerRef={driverformRef}
      enableReinitialize={true}
      onSubmit={async (values, { setFieldTouched, setSubmitting }) => {
        dispatch(clearAlertMessage());

        const { loyaltyId, selectedLoyaltyProgramValue } = values.loyalty;
        const { lastName, emailAddress } = values.driver;
        const loyaltyValidRequired = isLoyaltyAlreadyValidated(
          selectedLoyaltyProgramValue,          
          lastName,
          loyaltyId
        );
        console.log("Loyalty Details: ",values.loyalty, loyaltyValidRequired);
        if (
          loyaltyId &&
          selectedLoyaltyProgramValue !== LoyaltyProgramType.pleaseSelect &&
          !loyaltyValidRequired
        ) {
          let model = {
            LastName: lastName,
            LoyaltyProgram:
              selectedLoyaltyProgramValue === LoyaltyProgramType.ET
                ? "ET"
                : "ZL",
            EmailAddress: emailAddress,
            LoyaltyNumber: loyaltyId,
          };

          await axiosInstance
            .post("/drivers/validateloyalty", model)
            .then((response) => {
              console.log("LoyaltyProgram validation Result: ", response.data);
              let result = response.data.success;

              if (result) {
                dispatch(updateDriverDetails(values));
                dispatch(resetReservation());
                if (props.handleNext) props.handleNext(0, 1);
              } else {
                dispatch(showErrorAlertMessage(response.data.errors));
                dispatch(broadcastAnnouncement(response.data.errors));
                window.scrollTo(0, 0);
              }
            })
            .catch((error) => {
              console.error("There was an error!", error);
              appInsights.trackException({
                exception: error,
                properties: {
                  method: "driverdetails",
                  Component: "DR Driver details",
                },
                severityLevel: SeverityLevel.Error,
              });
              window.scrollTo(0, 0);
            });
        } else {
          console.log("driver details: ", values);
          dispatch(updateDriverDetails(values));
          dispatch(resetReservation());
          if (props.handleNext) props.handleNext(0, 1);
        }
      }}
    >
      {({
        values,
        isValid,
        dirty,
        setFieldValue,
        setFieldTouched,
        touched,
        handleChange,
        errors,
        isSubmitting,
      }) => (
        <Box
          boxShadow={3}
          p={3}
          bgcolor="background.paper"
          style={{ width: "100%" }}
        >
          {isBusy ? (
            <div style={{ textAlign: "center" }}>{t("pleaseWait")}</div>
          ) : (
            data && (
              <Grid container>
                <div ref={titleRef} tabIndex={-1} className={classes.focused}>
                  <Typography
                    variant="h1"
                    style={{
                      marginTop: 0,
                      marginBottom: "15px",
                    }}
                  >
                    {t("ReservationWizard.driverDetailsPageTitle")}
                  </Typography>
                </div>

                <Backdrop
                  className={classes.backdrop}
                  open={isBusy || isSubmitting}
                >
                  <CircularProgress />
                </Backdrop>
                <CompactJourneyDetailsView
                journeyUId={"00-00"}
                journeyDetails={props.journeyDetails}
              />
                <Grid item xs={12} style={{ marginTop: 10, color: "#AB0808" }}>
                  <Form style={{ width: "100%" }}>
                    <span style={{ fontWeight: "bold", color: "#000000" }}>
                      {t("lblRequiedFields")}
                    </span>

                    <Accordion
                      style={{ width: "100%" }}
                      id="sectionDriverDetails"
                      defaultExpanded
                    >
                      <h2>
                        <AccordionSummary
                          expandIcon={
                            <ExpandMoreIcon sx={{ color: "#FFFFFF" }} />
                          }
                          aria-controls="driverdetails"
                          id="driverdetails"
                          className={classes.heading}
                        >
                          {t(
                            "ReservationWizard.reservationPage.labelSectionDriver"
                          )}
                        </AccordionSummary>
                      </h2>

                      {!isBBCDriver ? (
                        <>
                          {" "}
                          {isBoboEnabled ? (
                            <Grid item md={12}>
                              <Box
                                flexDirection="row"
                                alignItems="center"
                                textAlign="center"
                                tabIndex={0}
                                style={{
                                  paddingBottom: 0,
                                  paddingTop: 0,
                                  paddingLeft: 25,
                                  paddingRight: 25,
                                }}
                              >
                                <Button
                                  id="btnBookForMyself"
                                  data-testid="btnBookForMyself"
                                  style={{
                                    width: "48%",
                                    height: "100%",
                                    wordBreak: "break-all",
                                    wordWrap: "break-word",
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    fontSize: "0.8em",
                                  }}
                                  color="primary"
                                  aria-label={t(
                                    "ReservationWizard.carClubPage.btnBookForMyself"
                                  )}
                                  variant="outlined"
                                  disabled={
                                    resMode === ReservationMode.Edit &&
                                    values.driver.isBOBOBooking
                                  }
                                  className={
                                    bookForMyself
                                      ? isSmXs
                                        ? classes.activeButtonClassSm
                                        : classes.activeButtonClass
                                      : isSmXs
                                      ? classes.inActiveButtonClassSm
                                      : classes.inActiveButtonClass
                                  }
                                  onClick={() => {
                                    if (!bookForMyself) {
                                      setBookForMyself(true);
                                      resetDriverDetails(true);
                                      resetLoyalty();
                                    }
                                  }}
                                  startIcon={bookForMyself && <CheckIcon />}
                                >
                                  {t(
                                    "ReservationWizard.carClubPage.btnBookForMyself"
                                  )}
                                </Button>
                                <Button
                                  id="btnBookForOthers"
                                  data-testid="btnBookForOthers"
                                  style={{
                                    width: "48%",
                                    height: "100%",
                                    wordBreak: "break-all",
                                    wordWrap: "break-word",
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    fontSize: "0.8em",
                                  }}
                                  color="primary"
                                  variant="outlined"
                                  disabled={
                                    resMode === ReservationMode.Edit &&
                                    !values.driver.isBOBOBooking
                                  }
                                  className={
                                    bookForMyself
                                      ? isSmXs
                                        ? classes.inActiveButtonClassSm
                                        : classes.inActiveButtonClass
                                      : isSmXs
                                      ? classes.activeButtonClassSm
                                      : classes.activeButtonClass
                                  }
                                  aria-label={t(
                                    "ReservationWizard.carClubPage.btnBookForOthers"
                                  )}
                                  onClick={() => {
                                    if (bookForMyself) {
                                      setBookForMyself(false);
                                      resetDriverDetails(false);
                                      resetLoyalty();
                                    }
                                  }}
                                  startIcon={!bookForMyself && <CheckIcon />}
                                >
                                  {t(
                                    "ReservationWizard.carClubPage.btnBookForOthers"
                                  )}
                                </Button>
                              </Box>
                            </Grid>
                          ) : (
                            <></>
                          )}
                          <>
                            {bookForMyself ? (
                              <AccordionDetails>
                                {values.driver === null ? (
                                  ""
                                ) : (
                                  <DriverDetails
                                    values={values.driver}
                                    isBOBOUser={false}
                                    resType={resMode}
                                    isConsentCountry={isConsentCountry}
                                    acceptConsent={values.driver.isConsent}
                                    onDataChange={onDriverDetailsChange}
                                    onConsentChange={handleChangeConsent}
                                  />
                                )}
                                <></>
                              </AccordionDetails>
                            ) : (
                              <AccordionDetails>
                                <BOBOSomeone
                                  initialDriver={values.driver}
                                  resType={resMode}
                                  isConsentCountry={isConsentCountry}
                                  acceptConsent={values.driver.isConsent}
                                  onDataChange={(e, x) => {
                                    onDriverDetailsChange(e, x);
                                    updateLoyaltyForBOBOUser(
                                      e.savedEmeraldClubNumber,
                                      e.savedEnterprisePlusNumber
                                    );
                                  }}
                                  handleConsentChange={handleChangeConsent}
                                  disableSearch={
                                    resMode === ReservationMode.Edit
                                  }
                                />
                                <></>
                              </AccordionDetails>
                            )}
                          </>
                        </>
                      ) : (
                        <AccordionDetails>
                            <p> BBC Driver</p>   
                                                 
                        </AccordionDetails>
                      )}
                    </Accordion>
                    {isLoyaltyEnabled && (
                      <Accordion
                        defaultExpanded
                        style={{ width: "100%" }}
                        id="sectionloyaltydetails"
                      >
                        <h2>
                          <AccordionSummary
                            expandIcon={
                              <ExpandMoreIcon sx={{ color: "#FFFFFF" }} />
                            }
                            aria-controls="loyaltydetails"
                            id="loyaltydetails"
                            className={classes.heading}
                          >
                            {t(
                              "ReservationWizard.reservationPage.labelSectionLoyalty"
                            )}
                          </AccordionSummary>
                        </h2>
                        <AccordionDetails>
                          <Grid container direction="row">
                            <Grid
                              item
                              xs={12}
                              md={3}
                              className={classes.gridItem}
                            >
                              <label
                                htmlFor={"loyaltyProgram"}
                                className={classes.labelText}
                              >
                                {t(
                                  "ReservationWizard.reservationPage.labelProgramAndLoyaltyId"
                                )}
                              </label>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={3}
                              className={classes.gridItem}
                            >
                              <FormControl variant="outlined" fullWidth>
                                <Select
                                  native
                                  disabled={resMode === ReservationMode.Edit}
                                  value={
                                    values.loyalty?.selectedLoyaltyProgramValue
                                      ? values.loyalty
                                          .selectedLoyaltyProgramValue
                                      : ""
                                  }
                                  fullWidth
                                  inputProps={{
                                    id: "loyaltyProgram",
                                    name: "loyalty.selectedLoyaltyProgramValue",
                                    "aria-label": t("loyaltyProgram"),
                                    "aria-haspopup": "listbox",
                                  }}
                                  title={t(
                                    "ReservationWizard.reservationPage.labelSelectProgramAndLoyaltyId"
                                  )}
                                  id="loyalty.selectedLoyaltyProgramValue"
                                  name="loyalty.selectedLoyaltyProgramValue"
                                  aria-describedby={"loyaltyProgramError"}
                                  error={
                                    errors &&
                                    errors.loyalty
                                      ?.selectedLoyaltyProgramValue !==
                                      undefined
                                      ? true
                                      : false
                                  }
                                  onChange={(e) => {
                                    updateLoyalty(
                                      values.driver.lastName,
                                      values.driver.emailAddress,
                                      e.target.value
                                    );
                                  }}
                                  onBlur={(e) => {
                                    setFieldTouched(
                                      "loyalty.selectedLoyaltyProgramValue",
                                      true
                                    );
                                  }}
                                >
                                  {values.loyalty?.loyaltyPrograms &&
                                    values.loyalty?.loyaltyPrograms?.map(
                                      (opt: any) => (
                                        <option
                                          key={opt.value}
                                          value={opt.value}
                                        >
                                          {t(
                                            "LoyaltyProgramOption." +
                                              getEnumKeyByEnumValue(
                                                LoyaltyProgramType,
                                                opt.value
                                              )
                                          )}
                                        </option>
                                      )
                                    )}
                                </Select>
                              </FormControl>
                              {errors.loyalty?.selectedLoyaltyProgramValue &&
                              touched.loyalty?.selectedLoyaltyProgramValue ? (
                                <span
                                  id="loyaltyProgramError"
                                  className={classes.errorWarning}
                                >
                                  {t(
                                    errors.loyalty
                                      ?.selectedLoyaltyProgramValue ?? ""
                                  )}
                                </span>
                              ) : (
                                <span id="loyaltyProgramError"></span>
                              )}
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              className={classes.gridItem}
                            >
                              <Field
                                name="loyalty.loyaltyId"
                                id="loyalty.loyaltyId"
                                value={
                                  values.loyalty.loyaltyId
                                    ? values.loyalty.loyaltyId
                                    : ""
                                }
                                onChange={(e) => {
                                  setFieldValue(
                                    "loyalty.loyaltyId",
                                    e.target.value.trim().slice(0, 9).toUpperCase()
                                  );
                                  setFieldTouched("loyalty.loyaltyId", true);
                                }}
                                onBlur={(e) => {
                                  setFieldTouched("loyalty.loyaltyId", true);
                                }}
                                onKeyUp={(e) => {
                                  setFieldTouched("loyalty.loyaltyId", true);
                                }}
                                fullWidth
                                style={{ paddingBottom: 0, paddingTop: 0 }}
                                inputProps={{
                                  "aria-describedby": "loyalty.loyaltyId",
                                  "aria-label": t("labelSectionLoyalty"),
                                }}
                                component={TextField}
                                variant="outlined"
                                error={
                                  errors.loyalty?.loyaltyId !== undefined
                                    ? true
                                    : false
                                }
                                disabled={
                                  resMode === ReservationMode.Edit ||
                                  values.loyalty.selectedLoyaltyProgramValue ===
                                    LoyaltyProgramType.pleaseSelect
                                }
                              />
                              {errors.loyalty?.loyaltyId &&
                              touched.loyalty?.loyaltyId ? (
                                <span
                                  id="loyaltyError"
                                  className={classes.errorWarning}
                                >
                                  {t(errors.loyalty?.loyaltyId)}
                                </span>
                              ) : (
                                <span id="loyaltyError"></span>
                              )}
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={12}
                              className={classes.gridItem}
                            >
                              <span
                                style={{
                                  fontStyle: "italic",
                                  fontWeight: "bold",
                                }}
                              >
                                {t(
                                  "ReservationWizard.reservationPage.labelLoyaltyWarningMessage"
                                )}
                              </span>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    )}

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "1em",
                      }}
                    >
                      <Button
                        id="btnBack"
                        color="primary"
                        aria-label={t("lblBackToPreviousStep")}
                        variant="contained"
                        onClick={() => {
                          if (props.handleBack) {
                            props.handleBack(0, 0);
                          }
                        }}
                      >
                        {t("btnBack")}
                      </Button>
                      <Button
                        id="btnSubmit"
                        color="primary"
                        variant="contained"
                        aria-label={t("lblContinueToNextStep")}
                        disabled={
                          !(
                            isValid &&
                            values.isValidDriver &&
                            values.isValidLoyalty
                          )
                        }
                        type="submit"
                      >
                        {t("btnContinue")}
                      </Button>
                    </div>
                  </Form>
                </Grid>
              </Grid>
            )
          )}
        </Box>
      )}
    </Formik>
  );
};

export default DriverDetailsForm;
